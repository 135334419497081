<template>
    <div id="app">
        <a-drawer destroyOnClose
                :title="title"
                :visible="show"
                :width="620"
                :mask-closable="false"
                :styles="styles"
                @close="_close"
        >
            <a-form-model ref="form" :model="formData" :rules="formRule">
                <a-row :gutter="8">
                    <a-col span="16">
                        <a-form-model-item label="协议名称" label-position="top" prop="title">
                            <a-input v-model="formData.title" placeholder="请输入协议名称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8">
                        <a-form-model-item label="标识符(不要随意修改)" label-position="top" prop="protocol">
                            <a-input v-model="formData.protocol" :disabled="formData.id>0" placeholder="请输入标识符" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="8">
                    <a-col span="24">
                        <vue-editor useCustomImageHandler @image-added="editorUpload" placeholder="请输入协议内容" v-model="formData.content"></vue-editor>
                    </a-col>
                </a-row>
            </a-form-model>
            <div class="demo-drawer-footer">
                <a-button style="margin-right: 8px" @click="_close">取消</a-button>
                <a-button type="primary" @click="doSubmit">确认提交</a-button>
            </div>
            <a-spin size="large" fix v-if="loading"/>
        </a-drawer>
    </div>
</template>

<script>
    import request,{editorUpload} from "@/config/request";
    import utils from "@/common/utils";
    import { VueEditor } from "vue2-editor";
    export default {
        components:{VueEditor},
        data(){
            return{
                show:false,
                title:'-',
                loading:false,
                styles: {
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static'
                },
                groups:[],
                formData: {
                    id:'',
                    title:'',
                    protocol:'',
                    status:1
                },
                formRule:{
                    title:[
                        {required:true,message:'请输入标题'}
                    ],
                    protocol:[
                        {required:true,message:'请输入标识符'}
                    ],
                }
            }
        },
        methods:{
            editorUpload,
            _show(form){
                this.show = true;
                this.$nextTick(()=>{
                    this.formData.id = '';
                    this.$refs.form.resetFields();
                    if(!form){
                        this.title = "添加"
                    }else{
                        this.formData = JSON.parse(JSON.stringify(form));
                        this.title = "修改";
                    }
                })
            },
            _close(){
                this.show = false;
                this.$emit('close')
            },
            init(){
                return{
                    title:'',
                    name:'',
                    type:'',
                    content:'',
                }
            },
            doSubmit(){
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        // console.log(this.formData);
                        utils.loading();
                        request.post('/platform/protocol/save',this.formData).then(ret=>{
                            // console.log(ret)
                            if(ret.code==200){
                                utils.success('操作成功！').then(()=>{
                                    this.$refs.form.resetFields();
                                    this.show = false;
                                    this.$emit('success');
                                })
                            }
                        }).catch(err=>{
                            console.log(err)
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
</style>
